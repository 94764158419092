import dayjs from "dayjs";
import { padd2 } from "./number";

export const getDisplayDate = (dateString: string, ignoreTime = false) => {
  if (!dateString) return "-";
  if (ignoreTime) {
    dateString = dateString.split("T")[0];
  }
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

export const getDisplayDateWithTime = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours12}:${minutesString} ${ampm}, ${month} ${day}, ${year}`;
};

export const getDisplayDateUTC = (dateString: string, ignoreTime = false) => {
  const date = new Date();
};

export const getCleanDateFormat = (dateString: string | null) => {
  if (!dateString) return "-";

  const date = new Date(dateString.split("T")[0]);

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day}, ${year}`;
};

export const yyyymmdd = (dateString: string) => {
  const date = new Date(dateString);
  const month = padd2(date.getMonth() + 1);
  const day = padd2(date.getDate());
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export function getRelativeDate(date) {
  const today = dayjs();

  const diffDays = today.diff(date, "day");
  const diffMonths = today.diff(date, "month");
  const diffYears = today.diff(date, "year");
  const diffDecades = Math.floor(diffYears / 10);

  if (diffDays < 1) {
    return "Today";
  } else if (diffDays === 1) {
    return "Yesterday";
  } else if (diffDays <= 30) {
    return `${diffDays} days ago`;
  } else if (diffMonths <= 12) {
    return `${diffMonths} months ago`;
  } else if (diffYears < 10) {
    return `${diffYears} years ago`;
  } else {
    return `${diffDecades} decades ago`;
  }
}

export const dispayDateAsString = (date: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateArr = date.split("T")[0].split("-");

  return `${months[parseInt(dateArr[1]) - 1]} ${padd2(parseInt(dateArr[2]))}, ${parseInt(dateArr[0])}`;
};
