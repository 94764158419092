import React, { useEffect, useRef, useState } from "react";
import "../../styles/pages/DashBoard/DashBoard.scss";
import "./HeaderComponent.scss";
import CustomTypography from "../../components/CustomTypoGraphy/CustomTypoGraphy";
import ImageComp from "../../components/CustsomImage/ImageComp";
import DropdownComponent from "../../components/DropDown/DropDown";
import {
  theme,
  Avatar,
  Space,
  Col,
  Row,
  Modal,
  Button,
  Badge,
  notification,
  Tooltip,
  Popover,
} from "antd";
import {
  ArrowLeftOutlined,
  MenuOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import FormButton from "../../components/FormButton/FormButton";
import { Header } from "antd/lib/layout/layout";
import { useAppSelector } from "../../store/hooks";
import { useNavigate, useLocation } from "react-router-dom";
import ContactSupport from "../ContactSupport/ContactSupport";
import { useDispatch } from "react-redux";
import {
  setLoading,
  setProgress,
  setProgressText,
} from "../../store/Loader/slice";
import importExportService from "../../services/importExport";
import createModal from "../../resources/images/success-modal.png";
import cityHelpImg from "../../resources/images/city-help.svg";
import {
  AdminOptions,
  ClientOptions,
  TransfereeOptions,
  getNotificationContent,
  supplierOptions,
} from "./HeaderComponent.contants";
import { logout } from "../../utils/logout";
import { useHasRole } from "../../customHooks/useHasRole";
import {
  APP_IMAGE_PREFIX,
  GET_EVENT_NOTIFICATIONS,
  GET_EVENT_NOTIFICATION_TOKEN,
} from "../../resources/constants";

import { notificationBell } from "../../resources/SVGImages/dashboard/svgone";

import notificationService from "../../services/notifications";
import NotificationTray from "./NotificationTray";
import { token } from "../../services";
import logos from "../../resources/logos";

interface headerProps {
  openSider: boolean;
  navigateTo?: string;
  toggleSider: React.Dispatch<React.SetStateAction<void>>;
}

const HeaderComponent: React.FC<headerProps> = ({
  toggleSider,
  navigateTo,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useAppSelector((state) => state?.login?.user);
  const slider = useAppSelector((state) => state?.displayConfig?.showSlider);
  const isUserRoleClient = userInfo?.role === "client";
  const isUserRoleAdmin = userInfo?.role === "admin";
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [openContactSupport, setOpenContactSupport] = useState(false);
  const location = useLocation();
  const [navigateCount, setNavigateCount] = useState(0);
  const [lastURL, setLastURL] = useState(window.location.href);
  const [isClicked, setIsClicked] = useState(false);
  const { hasRole } = useHasRole();
  const [isNotificationCalled, setIsNotificationCalled] = useState(false);

  const [notifications, setNotifications] = useState<any>({
    count: 0,
    list: [],
  });

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    setNavigateCount(navigateCount + 1);
    setLastURL(window.location.href);
  }, [window.location.href]);

  useEffect(() => {
    if (userInfo?.id && !isNotificationCalled) {
      setIsNotificationCalled(true);
      setTimeout(notificationInit, 1000);
    }
  }, [userInfo]);

  const [loadingNoti, setLoadingNoti] = useState(false);
  const lastPageNoti = useRef(false);
  const notificationPage = useRef(1);
  const notificationLimit = 10;

  const notificationInit = () => {
    const headers = {
      Authorization: `Bearer ${token?.access}`,
    };

    fetch(process.env.REACT_APP_API_BASEURL + GET_EVENT_NOTIFICATION_TOKEN, {
      headers,
    })
      .then(async (response: any) => {
        const res = await response.json();
        if (!res?.data?.notificationsToken) return;
        const events = new window.EventSource(
          process.env.REACT_APP_API_BASEURL +
            GET_EVENT_NOTIFICATIONS +
            `/${res?.data?.notificationsToken}`,
          {
            https2: false,
            reconnectionTime: 30000,
            heartbeatTimeout: Number.MAX_SAFE_INTEGER,
            https: {
              rejectUnauthorized: false,
            },
          } as any,
        );
        events.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data?.id) {
            api.open(
              getNotificationContent(
                data?.notificationTemplate?.sourceType,
                data?.notificationTemplate?.title,
                data?.content,
              ),
            );
          }
        };
        events.onerror = (error) => {};
      })
      .catch((error) => {});

    setLoadingNoti(true);
    notificationService
      .getNotifications({
        page: 1,
        limit: notificationLimit,
        deleted: false,
      })
      .then((res: any) => {
        setLoadingNoti(false);
        setNotifications({
          count: res?.data?.notificationsCount,
          list: res?.data?.notificationsList,
        });
      });
  };

  const blockRef = React.useRef(false);

  const getOldNotifications = () => {
    if (lastPageNoti.current) return;
    if (loadingNoti) return;
    if (blockRef.current) return;
    blockRef.current = true;
    notificationPage.current++;
    setLoadingNoti(true);

    notificationService
      .getNotifications({
        page: notificationPage.current,
        limit: notificationLimit,
        deleted: false,
      })
      .then((res: any) => {
        blockRef.current = false;
        setLoadingNoti(false);

        if (res?.data?.notificationsList?.length < 1) {
          lastPageNoti.current = true;
        }

        setNotifications((prev) => {
          const newNotifications = res?.data?.notificationsList.filter(
            (notification: any) => {
              return !prev.list.some(
                (prevNotification: any) =>
                  prevNotification.id === notification.id,
              );
            },
          );
          return {
            count: res?.data?.notificationsCount,
            list: [...prev.list, ...newNotifications],
          };
        });
      });
  };

  const clearNotification = (id: string, category: string) => {
    const payload = {};
    if (category === "INTERNAL_NOTIFICATION") {
      payload["notificationIds"] = [id];
    } else {
      payload["customNotificationIds"] = [id];
    }
    notificationService.clearNotification(payload);

    document
      .querySelector(`[data-id="noti-${id}"]`)
      ?.classList.add("hideNotification");

    setTimeout(() => {
      setNotifications({
        count: notifications.count - 1,
        list: notifications.list.filter(
          (item) => item.id !== id || item.category !== category,
        ),
      });
    }, 410);
  };

  const clearAllNotifications = () => {
    notificationService.clearNotification({
      notificationIds: [],
      customNotificationIds: [],
      all: true,
    });

    document.querySelectorAll(".item").forEach((item) => {
      item.classList.add("hideNotification");
    });

    setTimeout(() => {
      setNotifications({
        count: 0,
        list: [],
      });
    }, 410);
  };

  useEffect(() => {
    if (isClicked && lastURL === window.location.href) {
      navigate(navigateTo || "/", { state: { ref: "back" } });
    }
    setIsClicked(false);
  }, [location]);

  const handleBackBtn = () => {
    setIsClicked(true);
    if (navigateCount < 2) {
      navigate(navigateTo || "/", { state: { ref: "back" } });
    } else if (location.state?.ref === "back") {
      navigate(navigateTo || "/", { state: { ref: "back" } });
    } else {
      navigate(-1);
    }
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const isAdmin = useAppSelector(
    (state) => state?.login?.user_role === "admin",
  );
  const isClient = useAppSelector(
    (state) => state?.login?.user_role === "client",
  );

  const isTransferee = useAppSelector(
    (state) => state?.login?.user_role === "transferee",
  );

  const isSupplier = useAppSelector(
    (state) => state?.login?.user_role === "supplier",
  );

  const handleSettingClick = (e: any) => {
    if (e.key === "logout") {
      logout();
    }

    if (e.key === "support-team") {
      navigate("/support-team");
    }

    if (e.key === "help") {
      navigate("/help");
    }

    if (e.key === "user-profile") {
      navigate("/user-profile");
    }

    if (e.key === "support") {
      setOpenContactSupport(true);
    }
  };

  const syncMilo = () => {
    // dispatch(setLoading(true));
    // dispatch(setProgress(true));
    // dispatch(setProgressText(true));
    // importExportService
    //   .syncMilo()
    //   .then((res) => {
    //     setUpdateModal(true);
    //   })
    //   .finally(() => {
    //     dispatch(setLoading(false));
    //     dispatch(setProgress(false));
    //     dispatch(setProgressText(false));
    //   });
    navigate("/sync-milo");
  };

  const handleUpdateOk = () => {
    setUpdateModal(false);
  };

  const NotificationOption = (
    <Tooltip
      placement="bottomRight"
      overlayClassName="notificationTooltip"
      title={
        <NotificationTray
          notifications={notifications}
          loading={loadingNoti}
          clearNotification={clearNotification}
          clearAllNotifications={clearAllNotifications}
          getOldNotifications={getOldNotifications}
        />
      }
    >
      <span id="notificationBell">
        <Badge
          style={{ backgroundColor: "#F23F60" }}
          count={notifications.count}
        >
          <span>{notificationBell}</span>
        </Badge>
      </span>
    </Tooltip>
  );

  return (
    <div>
      {contextHolder}
      <Header
        className="dashBoardHeader"
        style={{ padding: 0, background: colorBgContainer }}
      >
        <Row align="middle" justify={"space-between"}>
          <Col md={12} className="dashboardHeaderLeft">
            <Space className="mobile" id="mobile">
              <ImageComp
                className="navigationLogo"
                src={logos.RELO_BLACK}
                onClick={() => {
                  navigate("/");
                }}
              />
            </Space>
            <Space className="leftIcon" id="leftIcon">
              {!slider && (
                <ArrowLeftOutlined
                  className="leftIcon"
                  id="headerBackBtn"
                  onClick={handleBackBtn}
                />
              )}
              {(isUserRoleClient || isUserRoleAdmin) && (
                <>
                  {(isUserRoleAdmin || isUserRoleClient) && (
                    <>
                      {isUserRoleClient && (
                        <>
                          {userInfo?.clientLogo && (
                            <img
                              id="clientLogo"
                              src={userInfo.clientLogo}
                              height={"40px"}
                              width={"80px"}
                              style={{
                                border: "2px solid #fff",
                              }}
                              onError={(e: any) =>
                                (e.target.style.display = "none")
                              }
                            ></img>
                          )}

                          <CustomTypography className="companyName">
                            {userInfo?.clientName}
                          </CustomTypography>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Space>
          </Col>
          <Col xs={0} sm={0} md={12} className="dashboardHeaderRight">
            <div className="dashboardRightInner">
              {isUserRoleAdmin && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  {hasRole("admin") && (
                    <FormButton
                      id="syncBtn"
                      className="MiloBtn"
                      btnText="Sync"
                      icon={<SyncOutlined />}
                      onClick={syncMilo}
                      ghost
                    />
                  )}
                  <FormButton
                    id="miloBtn"
                    className="MiloBtn"
                    btnText="Milo"
                    onClick={() => {
                      window.open(process.env.REACT_APP_MILO_URL, "_blank");
                    }}
                    ghost
                  />
                </div>
              )}
              {NotificationOption}
              <div className="dashboardUserBar desktop">
                <Space className="dashboardUserIcon" id="dashboardUserIcon">
                  <Avatar
                    src={
                      userInfo?.profilePicS3key
                        ? `${APP_IMAGE_PREFIX}${userInfo?.profilePicS3key}`
                        : `${process.env.REACT_APP_CDN_URL}/svgs/user.svg`
                    }
                    alt="user"
                    icon={!userInfo?.profilePicS3key ? <UserOutlined /> : null}
                    className="userIcon"
                  />
                </Space>
                {isAdmin && (
                  <Space className="profileIcon">
                    <DropdownComponent
                      className="settingDropdown"
                      triggerText={userInfo?.firstName || "-"}
                      options={AdminOptions}
                      onClick={(e) => handleSettingClick(e)}
                    />
                  </Space>
                )}
                {isClient && (
                  <Space className="profileIcon" id="profileIcon">
                    <DropdownComponent
                      className="settingDropdown"
                      triggerText={userInfo?.firstName || "-"}
                      options={ClientOptions}
                      onClick={(e) => handleSettingClick(e)}
                    />
                  </Space>
                )}
                {isTransferee && (
                  <Space className="profileIcon" id="profileIcon">
                    <DropdownComponent
                      className="settingDropdown"
                      triggerText={userInfo?.firstName || "-"}
                      options={TransfereeOptions}
                      onClick={(e) => handleSettingClick(e)}
                    />
                  </Space>
                )}
                {isSupplier && (
                  <Space className="profileIcon" id="profileIcon">
                    <DropdownComponent
                      className="settingDropdown"
                      triggerText={userInfo?.firstName || "-"}
                      options={supplierOptions}
                      onClick={(e) => handleSettingClick(e)}
                    />
                  </Space>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={0} className="dashboardHeaderRight">
            <div className="menuIconDiv">
              <Space className="mobile" size={"large"}>
                {NotificationOption}
                <MenuOutlined
                  id="menuIcon"
                  className="menuIcon"
                  onClick={() => {
                    toggleSider();
                  }}
                />
              </Space>
            </div>
          </Col>
        </Row>
      </Header>
      {openContactSupport && (
        <ContactSupport setOpenContactSupport={setOpenContactSupport} />
      )}

      {location.pathname !== "/city-iq" &&
        (userInfo?.role === "admin" ||
          ((userInfo?.role === "client" || userInfo?.role === "transferee") &&
            userInfo?.cityIQEnabled)) && (
          <span
            className="cityHelpSticky"
            onClick={() => {
              navigate("/city-iq");
            }}
          >
            <Popover
              content="CityIQ"
              placement="top"
              color="black"
              rootClassName="city-help-tooltip"
            >
              <img src={cityHelpImg} />
            </Popover>
          </span>
        )}

      <Modal
        className="centerModal"
        open={updateModal}
        onOk={handleUpdateOk}
        onCancel={handleUpdateOk}
        footer={[
          <div></div>,
          <Button
            id="doneBtn"
            key="submit"
            className="btnBlue"
            type="primary"
            onClick={handleUpdateOk}
          >
            Done
          </Button>,
        ]}
      >
        <img src={createModal} alt="success modal" />
        <h3>Milo Transferee Data has been successfully synced with the Relo</h3>
      </Modal>
    </div>
  );
};

export default HeaderComponent;
